import { DEVICE_INFO } from 'const';
// export let HOST = 'https://readism.hanoiapp.xyz';
export let HOST = 'https://readism.app.io.vn/api';
// if (DEVICE_INFO.IS_WEB && (
//   window.location.href.includes('readism.hanoiapp.xyz')
//   || window.location.href.includes('readism.vn')
// )) {
//   HOST = 'https://readism.hanoiapp.xyz';
// } else if (DEVICE_INFO.IS_WEB && window.location.href.includes('192.168')) {
//   HOST = 'http://192.168.0.103:4000';
// }